/** 메뉴 유형 */
export const menuType = {
    kor: 'KOR',
    eng: 'ENG',
}

/** 메뉴 데이터 소스 */
export const menuDataSource = [

    // 국문 > 기타
    { isShow: false, menuType: menuType.kor, menuId: 'KOR-MENU0000', parentMenuId: null, level: 0, title: '기타', path: '#', backgroundClass: 'sub-visual1' },
    { isShow: false, menuType: menuType.kor, menuId: 'KOR-MENU0100', parentMenuId: 'KOR-MENU0000', level: 1, title: '통합검색', path: '#' },
    { isShow: false, menuType: menuType.kor, menuId: 'KOR-MENU0101', parentMenuId: 'KOR-MENU0100', level: 2, title: '통합검색', isRepresentativeMenu: true, path: '/others/total-search' },

    // 국문 > 회사소개
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1000', parentMenuId: null, level: 0, title: '회사소개', path: '#', backgroundClass: 'sub-visual1' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1100', parentMenuId: 'KOR-MENU1000', level: 1, title: '연혁', path: '#', backgroundClass: 'sub-visual1' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1101', parentMenuId: 'KOR-MENU1100', level: 2, isRepresentativeMenu: true, title: '연혁', path: '/company/about/history', navigatePath: '회사소개 > 연혁 > 연혁' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1101', parentMenuId: 'KOR-MENU1100', level: 2, isRepresentativeMenu: false, title: 'CEO인사말', path: '/company/about/greet', navigatePath: '회사소개 > 연혁 > CEO인사말' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1200', parentMenuId: 'KOR-MENU1000', level: 1, title: '인력 및 조직', path: '#', backgroundClass: 'sub-visual1' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1201', parentMenuId: 'KOR-MENU1200', level: 2, isRepresentativeMenu: true, title: '인력 및 조직', path: '/company/group/organization', navigatePath: '회사소개 > 인력 및 조직 > 인력 및 조직' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1202', parentMenuId: 'KOR-MENU1200', level: 2, isRepresentativeMenu: false, title: '인재채용', path: '/company/group/talent-recruit-ment', navigatePath: '회사소개 > 인력 및 조직 > 인재채용' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1300', parentMenuId: 'KOR-MENU1000', level: 1, title: '오시는 길', path: '#', backgroundClass: 'sub-visual1' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1301', parentMenuId: 'KOR-MENU1300', level: 2, isRepresentativeMenu: true, title: '오시는 길', path: '/company/way-to-come/map', navigatePath: '회사소개 > 오시는 길 > 오시는 길' },
    
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1400', parentMenuId: 'KOR-MENU1000', level: 1, title: '고객센터', path: '#', backgroundClass: 'sub-visual1' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1401', parentMenuId: 'KOR-MENU1400', level: 2, isRepresentativeMenu: true, title: '윤리경영', path: '/company/service-center/dclaration', navigatePath: '회사소개 > 고객센터 > 윤리경영' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1402', parentMenuId: 'KOR-MENU1400', level: 2, isRepresentativeMenu: false, title: '고객서비스헌장', path: '/company/service-center/charter', navigatePath: '회사소개 > 고객센터 > 고객서비스헌장' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1413', parentMenuId: 'KOR-MENU1400', level: 2, isRepresentativeMenu: false, title: '금융소비자보호', path: '/company/service-center/consumer-protection', navigatePath: '회사소개 > 고객센터 > 금융소비자보호' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1414', parentMenuId: 'KOR-MENU1400', level: 2, isRepresentativeMenu: false, title: '투자권유준칙', path: '/company/service-center/investment-principle', navigatePath: '회사소개 > 고객센터 > 투자권유준칙' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1407', parentMenuId: 'KOR-MENU1400', level: 2, isRepresentativeMenu: false, title: '온라인 상담', path: '/company/service-center/advice', detailPath: ['/company/service-center/advice-edit'], navigatePath: '회사소개 > 고객센터 > 온라인 상담' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1405', parentMenuId: 'KOR-MENU1400', level: 2, isRepresentativeMenu: false, title: '공지사항', path: '/company/service-center/notice-board', detailPath: ['/company/service-center/notice-board-detail'], navigatePath: '회사소개 > 고객센터 > 공지사항' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1406', parentMenuId: 'KOR-MENU1400', level: 2, isRepresentativeMenu: false, title: 'FAQ', path: '/company/service-center/faq', navigatePath: '회사소개 > 고객센터 > FAQ' },
    
    { isShow: false, menuType: menuType.kor, menuId: 'KOR-MENU1500', parentMenuId: 'KOR-MENU1000', level: 1, title: '홈페이지 이용안내', path: '#', backgroundClass: 'sub-visual1' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1408', parentMenuId: 'KOR-MENU1500', level: 2, isRepresentativeMenu: true, title: '이용약관', path: '/company/service-center/agreement', navigatePath: '회사소개 > 홈페이지 이용안내 > 이용약관' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1403', parentMenuId: 'KOR-MENU1500', level: 2, isRepresentativeMenu: false, title: '개인정보보호정책', path: '/company/service-center/pri', navigatePath: '회사소개 > 홈페이지 이용안내 > 개인정보보호정책' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1404', parentMenuId: 'KOR-MENU1500', level: 2, isRepresentativeMenu: false, title: '개인(신용)정보보유현황', path: '/company/service-center/personal-info', detailPath: ['/company/service-center/personalInfo-edit'], navigatePath: '회사소개 > 홈페이지 이용안내 > 개인(신용)정보보유현황' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1411', parentMenuId: 'KOR-MENU1500', level: 2, isRepresentativeMenu: false, title: '내부통제기준', path: '/company/service-center/internalControl', navigatePath: '회사소개 > 홈페이지 이용안내 > 내부통제기준' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1412', parentMenuId: 'KOR-MENU1500', level: 2, isRepresentativeMenu: false, title: '부동산신탁약관공시', path: '/company/service-center/realestate-agreement', navigatePath: '회사소개 > 홈페이지 이용안내 > 부동산신탁약관공시' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1410', parentMenuId: 'KOR-MENU1500', level: 2, isRepresentativeMenu: false, title: '이메일주소 무단수집거부', path: '/company/service-center/no-mail', navigatePath: '회사소개 > 홈페이지 이용안내 > 이메일주소 무단수집거부' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU1409', parentMenuId: 'KOR-MENU1500', level: 2, isRepresentativeMenu: false, title: '사이트맵', path: '/company/service-center/sitemap', navigatePath: '회사소개 > 홈페이지 이용안내 > 사이트맵' },

    // 국문 > 토지신탁사업
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2000', parentMenuId: null, level: 0, title: '신탁사업', path: '#', backgroundClass: 'sub-visual2' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2100', parentMenuId: 'KOR-MENU2000', level: 1, title: '토지신탁', path: '#', backgroundClass: 'sub-visual2' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2101', parentMenuId: 'KOR-MENU2100', level: 2, isRepresentativeMenu: true, title: '차입형토지신탁', path: '/land-trust/borrow-develop/leverag-land-trust', navigatePath: '토지신탁사업 > 토지신탁 > 차입형토지신탁' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2102', parentMenuId: 'KOR-MENU2100', level: 2, isRepresentativeMenu: true, title: '관리형토지신탁', path: '/land-trust/borrow-develop/management-land-trust', navigatePath: '토지신탁사업 > 토지신탁 > 관리형토지신탁' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2200', parentMenuId: 'KOR-MENU2000', level: 1, title: '비토지신탁', path: '#', backgroundClass: 'sub-visual2' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2201', parentMenuId: 'KOR-MENU2200', level: 2, isRepresentativeMenu: true, title: '담보·관리·처분신탁', path: '/land-trust/non-develop/non-land-trust', navigatePath: '토지신탁사업 > 담보·관리·처분신탁 > 비토지신탁' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2203', parentMenuId: 'KOR-MENU2200', level: 2, isRepresentativeMenu: true, title: '분양관리신탁', path: '/land-trust/non-develop/parcel-out-management', navigatePath: '토지신탁사업 > 담보·관리·처분신탁 > 분양관리신탁' },
    // { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2202', parentMenuId: 'KOR-MENU2200', level: 2, isRepresentativeMenu: false, title: '대리사무', path: '/land-trust/non-develop/proxy', navigatePath: '토지신탁사업 > 담보·관리·처분신탁 > 대리사무' },
    // { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2203', parentMenuId: 'KOR-MENU2200', level: 2, isRepresentativeMenu: false, title: '국공유지신탁', path: '/land-trust/non-develop/public', navigatePath: '토지신탁사업 > 담보·관리·처분신탁 > 국공유지신탁' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2204', parentMenuId: 'KOR-MENU2200', level: 2, isRepresentativeMenu: false, title: '부동산컨설팅', path: '/land-trust/non-develop/consulting', navigatePath: '토지신탁사업 > 담보·관리·처분신탁 > 부동산컨설팅' },
    // { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2205', parentMenuId: 'KOR-MENU2200', level: 2, isRepresentativeMenu: false, title: 'PCF Master', path: '/land-trust/non-develop/pcf', navigatePath: '토지신탁사업 > 담보·관리·처분신탁 > PCF Master' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2300', parentMenuId: 'KOR-MENU2000', level: 1, title: '입주정보', path: '#', backgroundClass: 'sub-visual2' },
    {
        isShow: true,
        menuType: menuType.kor,
        menuId: 'KOR-MENU2301',
        parentMenuId: 'KOR-MENU2300',
        level: 2,
        isRepresentativeMenu: true,
        title: '현장정보',
        path: '/land-trust/move-In-Info/field-info',
        detailPath: [
            '/land-trust/move-In-Info/construction-view',
            '/land-trust/move-In-Info/construction-parcel',
            '/land-trust/move-In-Info/construction-construction-etc',
            '/land-trust/move-In-Info/construction-model',
            '/land-trust/move-In-Info/construction-process',
            '/land-trust/move-In-Info/construction-move-in',
            '/land-trust/move-In-Info/construction-complex',
            '/land-trust/move-In-Info/construction-feature',
            '/land-trust/move-In-Info/construction-plane',
            '/land-trust/move-In-Info/construction-interior',
        ],
        navigatePath: '토지신탁사업 > 입주정보 > 현장정보'
    },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2302', parentMenuId: 'KOR-MENU2300', level: 2, isRepresentativeMenu: false, title: '분양대금조회', path: '/land-trust/move-In-Info/payment', detailPath: ['/land-trust/move-In-Info/payment-view'], navigatePath: '토지신탁사업 > 입주정보 > 분양대금조회' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2400', parentMenuId: 'KOR-MENU2000', level: 1, title: '공매공고', path: '#', backgroundClass: 'sub-visual2'},
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2401', parentMenuId: 'KOR-MENU2400', level: 2, isRepresentativeMenu: true, title: '공매공고', path: '/land-trust/sale/short-notice', detailPath: ['/land-trust/sale/short-notice-detail'], navigatePath: '토지신탁사업 > 공매공고 > 공매공고' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2500', parentMenuId: 'KOR-MENU2000', level: 1, title: '기타공고', path: '#', backgroundClass: 'sub-visual2' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU2501', parentMenuId: 'KOR-MENU2500', level: 2, isRepresentativeMenu: true, title: '기타공고', path: '/land-trust/etc/other-notice', detailPath: ['/land-trust/etc/other-notice-detail'], navigatePath: '토지신탁사업 > 기타공고 > 기타공고' },

    // 국문 > 도시정비사업
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU3000', parentMenuId: null, level: 0, title: '도시정비사업', path: '#', backgroundClass: 'sub-visual3' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU3100', parentMenuId: 'KOR-MENU3000', level: 1, title: '신탁방식 도시정비사업', path: '#', backgroundClass: 'sub-visual3' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU3101', parentMenuId: 'KOR-MENU3100', level: 2, isRepresentativeMenu: true, title: '신탁방식 도시정비사업', path: '/city-maintain/trust-system/redevelop', navigatePath: '도시정비사업 > 신탁방식 도시정비사업 > 신탁방식 도시정비사업' },

    // 국문 > 리츠·투자사업
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU4000', parentMenuId: null, level: 0, title: '리츠·투자사업', path: '#', backgroundClass: 'sub-visual4' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU4100', parentMenuId: 'KOR-MENU4000', level: 1, title: '리츠사업', path: '#', backgroundClass: 'sub-visual4' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU4101', parentMenuId: 'KOR-MENU4100', level: 2, isRepresentativeMenu: true, title: '리츠소개', path: '/reits-invest/Investment/reits-business', navigatePath: '리츠·투자사업 > 리츠·투자사업 > 리츠소개' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU4102', parentMenuId: 'KOR-MENU4100', level: 2, isRepresentativeMenu: true, title: '리츠 퍼포먼스', path: '/reits-invest/Investment/reits-performance', navigatePath: '리츠·투자사업 > 리츠·투자사업 > 리츠 퍼포먼스' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU4103', parentMenuId: 'KOR-MENU4100', level: 2, isRepresentativeMenu: true, title: '리츠 운영 현황', path: '/reits-invest/Investment/reits-operation-status', navigatePath: '리츠·투자사업 > 리츠·투자사업 > 리츠 운영 현황' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU4200', parentMenuId: 'KOR-MENU4000', level: 1, title: '투자사업', path: '#', backgroundClass: 'sub-visual4' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU4201', parentMenuId: 'KOR-MENU4200', level: 2, isRepresentativeMenu: true, title: 'PFV', path: '/reits-invest/Investment/pfv-business', navigatePath: '리츠·투자사업 > 투자사업 > PFV' },
    // { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU4201', parentMenuId: 'KOR-MENU4200', level: 2, isRepresentativeMenu: false, title: '해외사업', path: '/reits-invest/Investment/overseas-business', navigatePath: '리츠·투자사업 > 투자사업 > 해외사업' },

    // 국문 > IR·PR센터
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5000', parentMenuId: null, level: 0, title: 'IR·PR센터', path: '#', backgroundClass: 'sub-visual5' },
    // { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5100', parentMenuId: 'KOR-MENU5000', level: 1, title: 'ESG', path: '#' },
    // { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5101', parentMenuId: 'KOR-MENU5100', level: 2, title: 'ESG', path: '/ir-pr-center/esg/EnviroSocialGov', navigatePath: 'IR·PR센터 > ESG > ESG' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5200', parentMenuId: 'KOR-MENU5000', level: 1, title: '사업 포트폴리오', path: '#', backgroundClass: 'sub-visual5' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5201', parentMenuId: 'KOR-MENU5200', level: 2, isRepresentativeMenu: true, title: '사업 포트폴리오', path: '/ir-pr-center/business-portfolio/portfolio', navigatePath: 'IR·PR센터 > 사업 포트폴리오 > 사업 포트폴리오' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5202', parentMenuId: 'KOR-MENU5200', level: 2, isRepresentativeMenu: false, title: 'IR 자료', path: '/ir-pr-center/business-portfolio/ir-data', detailPath: ['/ir-pr-center/business-portfolio/ir-data-detail'], navigatePath: 'IR·PR센터 > 사업 포트폴리오 > IR 자료' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5203', parentMenuId: 'KOR-MENU5200', level: 2, isRepresentativeMenu: false, title: '배당현황', path: '/ir-pr-center/business-portfolio/dividend-status', navigatePath: 'IR·PR센터 > 사업 포트폴리오 > 배당현황' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5300', parentMenuId: 'KOR-MENU5000', level: 1, title: '경영공시', path: '#', backgroundClass: 'sub-visual5' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5301', parentMenuId: 'KOR-MENU5300', level: 2, isRepresentativeMenu: true, title: '공시자료', path: '/ir-pr-center/management/sales-report', detailPath: ['/ir-pr-center/management/sales-report-detail'], navigatePath: 'IR·PR센터 > 경영공시 > 공시자료' },
    //{ isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5302', parentMenuId: 'KOR-MENU5300', level: 2, isRepresentativeMenu: false, title: '수시공시', path: '/ir-pr-center/management/payout-disclosure', detailPath: ['/ir-pr-center/management/payout-disclosure-detail'], navigatePath: 'IR·PR센터 > 경영공시 > 수시공시' },
    //{ isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5303', parentMenuId: 'KOR-MENU5300', level: 2, isRepresentativeMenu: false, title: '지배구조공시', path: '/ir-pr-center/management/ground-disclosure', detailPath: ['/ir-pr-center/management/ground-disclosure-detail'], navigatePath: 'IR·PR센터 > 경영공시 > 지배구조공시' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5304', parentMenuId: 'KOR-MENU5300', level: 2, isRepresentativeMenu: false, title: '결산공시', path: '/ir-pr-center/management/settlement-disclosure', detailPath: ['/ir-pr-center/management/settlement-disclosure-detail'], navigatePath: 'IR·PR센터 > 경영공시 > 결산공시' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5305', parentMenuId: 'KOR-MENU5300', level: 2, isRepresentativeMenu: false, title: '기타공시', path: '/ir-pr-center/management/etc-disclosure', detailPath: ['/ir-pr-center/management/etc-disclosure-detail'], navigatePath: 'IR·PR센터 > 경영공시 > 기타공시' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5400', parentMenuId: 'KOR-MENU5000', level: 1, title: '리츠공시', path: '#', backgroundClass: 'sub-visual5' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5401', parentMenuId: 'KOR-MENU5400', level: 2, isRepresentativeMenu: true, title: '리츠공시', path: '/ir-pr-center/reits/reits-disclosure', detailPath: ['/ir-pr-center/reits/reits-disclosure-detail'], navigatePath: 'IR·PR센터 > 리츠공시 > 리츠공시' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5500', parentMenuId: 'KOR-MENU5000', level: 1, title: 'Koreit 아카이브', path: '#', backgroundClass: 'sub-visual5' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5501', parentMenuId: 'KOR-MENU5500', level: 2, isRepresentativeMenu: true, title: 'CI 소개', path: '/ir-pr-center/koreit-archive/ci-bi', navigatePath: 'IR·PR센터 > Koreit 아카이브 > CI 소개' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5502', parentMenuId: 'KOR-MENU5500', level: 2, isRepresentativeMenu: false, title: '브로슈어', path: '/ir-pr-center/koreit-archive/brochure', navigatePath: 'IR·PR센터 > Koreit 아카이브 > 브로슈어' },
    { isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5503', parentMenuId: 'KOR-MENU5500', level: 2, isRepresentativeMenu: false, title: '코아루체 소개', path: '/ir-pr-center/koreit-archive/koaroo-penmanship', navigatePath: 'IR·PR센터 > Koreit 아카이브 > 코아루체 소개' },
    //{ isShow: true, menuType: menuType.kor, menuId: 'KOR-MENU5504', parentMenuId: 'KOR-MENU5500', level: 2, isRepresentativeMenu: false, title: '기업/브랜드 필름', path: '/ir-pr-center/koreit-archive/enterprise-film', navigatePath: 'IR·PR센터 > Koreit 아카이브 > 기업/브랜드 필름' },

    // 영문 > About Koreit
    { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU1000', parentMenuId: null, level: 0, title: 'About Koreit', path: '#', backgroundClass: 'sub-visual1' },
    { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU1100', parentMenuId: 'ENG-MENU1000', level: 1, title: 'CEO Message', path: '/en/about-koreit/ceo-message' },
    { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU1200', parentMenuId: 'ENG-MENU1000', level: 1, title: 'Map of Location', path: '/en/about-koreit/map-of-location' },
    { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU1300', parentMenuId: 'ENG-MENU1000', level: 1, title: 'Milestones', path: '/en/about-koreit/milestones' },
    { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU1400', parentMenuId: 'ENG-MENU1000', level: 1, title: 'Organiztion', path: '/en/about-koreit/organization' },

    // 영문 > Koreit Service
    { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU2000', parentMenuId: null, level: 0, title: 'Koreit Service', path: '#', backgroundClass: 'sub-visual2' },
    { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU3100', parentMenuId: 'ENG-MENU2000', level: 1, title: 'Land Trust', path: '/en/koreit-service/land-trust' },
    { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU3200', parentMenuId: 'ENG-MENU2000', level: 1, title: 'Land Trust-led\nUrban Improvement Project', path: '/en/koreit-service/land-trust-led-urban-improvement-project' },
    { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU3300', parentMenuId: 'ENG-MENU2000', level: 1, title: 'REITs', path: '/en/koreit-service/reits' },
    { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU3300', parentMenuId: 'ENG-MENU2000', level: 1, title: 'Investment Business', path: '/en/koreit-service/investment-business' },
    // { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU2100', parentMenuId: 'ENG-MENU2000', level: 1, title: 'Introduction', path: '/en/koreit-service/introduction' },
    // { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU2200', parentMenuId: 'ENG-MENU2000', level: 1, title: 'Land Development Trust', path: '/en/koreit-service/land-development-trust' },
    // { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU2300', parentMenuId: 'ENG-MENU2000', level: 1, title: 'Management Trust', path: '/en/koreit-service/management-trust' },
    // { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU2400', parentMenuId: 'ENG-MENU2000', level: 1, title: 'Profit Certificate Trust', path: '/en/koreit-service/profit-certificate-trust' },
    // { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU2500', parentMenuId: 'ENG-MENU2000', level: 1, title: 'Proxy Business', path: '/en/koreit-service/proxy-business' },
    // { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU2600', parentMenuId: 'ENG-MENU2000', level: 1, title: 'Real Estate Consulting', path: '/en/koreit-service/real-estate-consulting' },
    // { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU2700', parentMenuId: 'ENG-MENU2000', level: 1, title: 'REITs', path: '/en/koreit-service/reits' },
    // { isShow: true, menuType: menuType.eng, menuId: 'ENG-MENU2800', parentMenuId: 'ENG-MENU2000', level: 1, title: 'ABS', path: '/en/koreit-service/abs' },
];



/**
 * 메뉴관리 클래스
 */
class MenuManagement {

    /**
     * 생성자
     */
    constructor() { }

    /**
     * 메뉴정보 반환
     * @param {String} menuType : 메뉴 유형 (KOR:국문, ENG: 영문)
     * @returns 
     */
    getMenuItems(menuType) {

        const menuItems = [];

        // 하위메뉴 탐색 재귀함수
        const getChildMenuItems = menuInfo => {

            // 대상메뉴에 해당하는 자식메뉴 목록
            const childMenuItems = menuDataSource.filter(item => item.parentMenuId === menuInfo.menuId && item.menuType === menuType && item.isShow);

            // 자식메뉴 목록 결과
            const resultChildMenuItems = new Array();

            childMenuItems.forEach(childMenuInfo => {

                childMenuInfo.children = getChildMenuItems(childMenuInfo);
                resultChildMenuItems.push(childMenuInfo);
            });

            return resultChildMenuItems;
        }

        // 메뉴목록 가공 (트리형식으로)
        menuDataSource.filter(item => item.level === 0 && item.menuType === menuType && item.isShow).forEach(topMenuInfo => {

            topMenuInfo.children = getChildMenuItems(topMenuInfo);
            menuItems.push(topMenuInfo);
        });

        return menuItems;
    }
}

export const menuManagement = new MenuManagement();