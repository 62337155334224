<template>
    <footer id="koreit-footer">
        <div class="inner">
            <div class="f-logo">
                <img v-if="state.menuType === menuType.eng" src="@/assets/images/eng_logo_w.png" alt="Koreit">
                <img v-else src="@/assets/images/logo_footer.png" alt="한국토지신탁">
            </div>

            <div v-if="state.menuType === menuType.eng" class="f-info">
                <ul>
                    <!-- <li><span>ADDRESS</span>16-19th Fl, 137, Teheran-ro, Gangnam-gu, Seoul, 06132, Korea</li> -->
                    <li>16-19th Fl, 137, Teheran-ro, Gangnam-gu, Seoul, 06132, Korea</li>
                    <li><span>TEL</span>+82-2-3451-1100</li>
                </ul>
                <p class="copyright">Ⓒ 2023 KOREIT. ALL RIGHTS RESERVED.</p>
            </div>
            <div v-else class="f-info">
                <ul>
                    <li><span>주소</span>06132 서울 강남구 테헤란로 137(역삼동) 코레이트타워 16F~19F (주)한국토지신탁</li>
                    <li><span>대표번호</span>02-3451-1100 / 02-3451-1114</li>
                </ul>
                <p class="copyright">Ⓒ 2023 KOREIT. ALL RIGHTS RESERVED.</p>
            </div>
            
            <ul v-if="props.isKorMenuOn" class="f-util">
                <!-- <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1405')" title="이메일주소무단수집거부">이메일주소무단수집거부</a></li>
                <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1403')" title="개인정보취급방침">개인정보취급방침</a></li>
                <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1408')" title="이용약관">이용약관</a></li>
                <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1407')" title="온라인상담">온라인상담</a></li> -->
                <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1408')" title="이용약관">이용약관</a></li>
                <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1403')" title="개인정보보호정책" style="color: #4D95D8;">개인정보보호정책</a></li>
                <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1404')" title="개인(신용)정보보유현황">개인(신용)정보보유현황</a></li>
                <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1411')" title="내부통제기준">내부통제기준</a></li>
                <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1412')" title="부동산신탁약관공시">부동산신탁약관공시</a></li>
                <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1410')" title="이메일주소무단수집거부">이메일주소무단수집거부</a></li>
                <li><a href="#" @click="props.onFooterMenuClick($event, 'KOR-MENU1409')" title="사이트맵">사이트맵</a></li>
            </ul>
        </div>
        <button v-if="state.menuType === menuType.eng" type="button" title="Go to Top" class="go-top" @click="eventHandler.onTopScrollClick">
            <span class="text_hidden">Go to Top</span>
        </button>
        <button v-else type="button" title="상단으로 이동" class="go-top" @click="eventHandler.onTopScrollClick">
            <span class="text_hidden">상단으로 이동</span>
        </button>
    </footer>
</template>
<script>
import { reactive } from '@vue/reactivity';
import { menuType } from '@/modules/menu-management.js';
export default {
    props: [

        // 국문 메뉴 활성여부
        'isKorMenuOn',

        // 푸터 메뉴 클릭 이벤트 핸들러
        'onFooterMenuClick',
    ],
    setup(props) {

        // state (변수)
        const state = reactive({

            // 현재 메뉴 유형
            menuType: sessionStorage.getItem('menuType'),
        });

        // 이벤트 핸들러
        const eventHandler = {
            
            /**
             * 최상단으로 스크롤 이동 버튼 클릭 이벤트 처리
             */
            onTopScrollClick: () => {

                window.scrollTo({ top: 0, behavior: "smooth" }); 
            },
        }
        
        return {
            props,
            state,
            eventHandler,
            menuType,
        }
    },
}
</script>
