import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * KRT게시판 공통 기능 클래스
 */
class KrtBoardCommon {

    /**
     * 생성자
     */
    constructor() {
        /**
         * 기존 쿼리에 페이징 쿼리가 사용하고 있음
         * 현재는 프라임뷰 데이터테이블을 사용하고 있기 때문에 페이징 쿼리가 의미없음
         * 모든 목록을 조회하기 위해 int max 사이즈 부여함
         */
         this.currentPage = 1;
         this.listSize = 2147483647; // [주의] 쿼리 수정 하면 안됨. top size = int.max
         this.gridTopSize = 15;      // [주의] 쿼리 상의 top size 와 다름. 이것은 그리드용 top size.
    }

    /**
     * 게시판 정보 가져오기     
     * @param string boardCategory 
     * @returns 
     */
    getBoardInfo(systemCode, boardCategory) {
        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.BoardInfoBiz';
            request.methodId = 'GetBoardInfo';
            request.parameters.systCode = systemCode;
            //request.parameters.boardCategory = this.boardCategory;   
            request.parameters.boardCategory = boardCategory;  // as-is same.. querystring["boardcategory"]

            service.execute(request).then(response => {
                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows[0]);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };
    
    /**
     * KRT 게시판 목록 가져오기
     */
    getKRTBoardContentList(systemCode, boardCategory, searchType, searchKeyword) {

        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
            request.methodId = 'GetKRTBoardContentList';
            request.parameters.dic = JSON.stringify({
                
                SystCode: systemCode,
                BoardCategory: boardCategory,
                Title: searchType === '1' ? searchKeyword : '',
                Content: searchType === '2' ? searchKeyword : '',
                UseYn: 'Y',
                UserName: searchType === '3' ? searchKeyword : '',
                UserNo: '',
                CodeVal: '',
                CurPage: '1',
                ListSize: this.listSize,
                ExpiredDt: '',
                
            });

            
            service.execute(request).then(response => {

                const resultData = response.data.result.value.Table.rows;
                resolve(resultData);
            })
            .catch(error => reject(error))
        })
    }

    /**
     * KRT 게시판 목록 가져오기2 (DataTable 안쓰는버전)
     */
    getKRTBoardContentList2(systemCode, boardCategory, searchType, searchKeyword, CurPage) {

        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
            request.methodId = 'GetKRTBoardContentList';
            request.parameters.dic = JSON.stringify({
                
                SystCode: systemCode,
                BoardCategory: boardCategory,
                Title: searchType === '1' ? searchKeyword : '',
                Content: searchType === '2' ? searchKeyword : '',
                UseYn: 'Y',
                UserName: searchType === '3' ? searchKeyword : '',
                UserNo: '',
                CodeVal: '',
                CurPage: CurPage,
                ListSize: 15,
                ExpiredDt: '',
                
            });

            
            service.execute(request).then(response => {
                
                const resultData = response.data.result.value;
                resolve(resultData);
            })
            .catch(error => reject(error))
        })
    }


    /**
     * KRT 게시판  상세 목록 조회
     */
    getKRTBoardContent(seq, systemCode, boardCategory, groupNo) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
            request.methodId = 'GetKRTBoardContent';
            request.parameters.dic = JSON.stringify({
                Seq: seq,
                SystCode: systemCode,
                BoardCategory: boardCategory,
                GroupNo: groupNo,             
            });

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
                
            }).catch(error => reject(error));
        });
    }

    /**
     * # Get Board Prev & Next Data
     * KRT 게시판 이전글 다음글 조회 
     */
    getKRTBoardContentPreNext(seq, systemCode, boardCategory, groupNo) {

        const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
        const service = new FoxRestBizClient(url, false);
        const request = new FoxBizRequest();
        request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
        request.methodId = 'GetKRTBoardContentPreNext';
        request.parameters["dic"] = JSON.stringify({
            Seq: seq,
            SystCode: systemCode,
            BoardCategory: boardCategory,
            GroupNo: groupNo,
            Title: '',
            Content: '',
            UseYn: 'Y',
            UserName: '',
            UserNo: '',
            CodeVal: '',  
        });

        return new Promise((resolve, reject) => {
            var sf = function (res) {
                var ret = new Object();
                ret["prevData"] = res.data.result.value.Table.rows;    // 이전글 (as-is 의심스럽다)
                ret["nextData"] = res.data.result.value.Table1.rows;   // 다음글 (as-is 의심스럽다)
                resolve(ret);
            };
            service.execute(request).then(sf).catch(error => reject(error));
        });
    }

    getMediaFiles(systemCode, boardCategory, mediaTypeCode, baseUrl) {

        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
            request.methodId = 'GetMediaList';
            request.parameters = {
                SystCode: systemCode,
                BoardCategory: boardCategory,
                CodeVal: mediaTypeCode,
                BaseUrl: baseUrl,  
            };
            
            service.execute(request).then(response => {
                const rows = response.data.result.value.Table.rows;
                resolve(rows);
            }).catch(error => reject(error));
        });
    }

    /**
     * 메인화면에 표시되는 게시판 및 팝업 조회
     * @param {String} boardCategory 
     */
    getPopupZon(boardCategory) {

        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
            request.methodId = 'GetKRTBoardByMain';
            request.parameters.boardCategory = boardCategory
            
            service.execute(request).then(response => {
                const rows = response.data.result.value.Table.rows;
                resolve(rows);
            }).catch(error => reject(error));
        });
    }
}

export const krtBoardCommon = new KrtBoardCommon();