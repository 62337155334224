/**
 * 공통 스토어 모듈
 */
export default {
    state: {

        // 클라이언트 IP
        clientIp: null,

        // 로딩여부
        isLoading: false,

        // 모바일 단말기 여부
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
        
        fileBaseDirectoryPath: '/AttachFiles/',

        // 게시판 업로드/다운로드 파일 저장 위치
        boardSubDirectoryPath: '/Board/',

        // 파일 업로드 핸들러 Url 반환 (물리파일 업로드)
        fileUploadUrl: '/UploadFileHandler.ashx',

        // 파일 다운로드 핸들러 Url 반환 (물리파일 다운로드)
        fileDownloadUrl: '/DownloadFileHandler.ashx',

        // 파일 삭제 핸들러 Url (물리파일 삭제)
        fileDeleteUrl: '/DeleteFileHandler.ashx',
    },
    getters: {

        /**
         * 클라이언트 IP 반환
         * @param {*} state 
         * @returns 
         */
        clientIp: (state) => state.clientIp,

        /**
         * 프로그래스 다이얼로그 실행여부 반환
         * @param {*} state 
         * @returns 
         */
        isLoading: (state) => state.isLoading,

        /**
         * 모바일 단말기 여부 반환
         * @param {*} state 
         * @returns 
         */
        isMobile: (state) => state.isMobile,

        /**
         * 파일 기본 경로
         * @param {*} state 
         * @returns 
         */
        fileBaseDirectoryPath: (state) => state.fileBaseDirectoryPath,

        /**
         * 게시판 업로드/다운로드 파일 저장 위치 반환
         * @param {*} state 
         */
        boardSubDirectoryPath: (state) => state.boardSubDirectoryPath,

        /**
         * 파일 업로드 핸들러 Url 반환 (물리파일 업로드)
         * @param {*} state 
         * @returns 
         */
        fileUploadUrl: (state) => state.fileUploadUrl,

        /**
         * 파일 다운로드 핸들러 Url 반환 (물리파일 다운로드)
         * @param {*} state 
         * @returns 
         */
        fileDownloadUrl: (state) => state.fileDownloadUrl,

        /**
         * 파일 삭제 핸들러 Url 반환 (물리파일 삭제)
         * @param {*} state 
         * @returns 
         */
        fileDeleteUrl: (state) => state.fileDeleteUrl,
    },
    mutations: {

        /**
         * 프로그래스 다이얼로그 실행여부 설정
         * @param {*} state 
         * @param {*} value 
         */
        setLoadingStatus: (state, value) => { state.isLoading = value },

        /**
         * 클라이언트 IP 설정
         * @param {*} state 
         * @param {*} value 
         */
        setClientIp: (state, value) => { state.clientIp = value },
    },
    actions: { }
}