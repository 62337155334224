<template>
    <!-- 영문최상위 메뉴 -->
    <ul v-if="state.menuType === menuType.eng" @mouseover="props.onRootMenuMouseOver" @mouseout="props.onRootMenuMouseOut">
        <li v-for="rootMenuInfo in state.menuItems" v-bind:key="rootMenuInfo" class="depth2">
            <a :href="rootMenuInfo.path" :title="rootMenuInfo.title" @click="props.onRootMenuClick($event, props.menuNavigationType, rootMenuInfo)">{{rootMenuInfo.title}}</a>
            <ul>
                <li v-for="twoDepthMenuInfo in rootMenuInfo.children" v-bind:key="twoDepthMenuInfo" :class="{ depth3: twoDepthMenuInfo.children.length }">
                    <a v-if="state.currentPagePathName === state.engMainPagePathName"
                        :href="twoDepthMenuInfo.path"
                        :title="twoDepthMenuInfo.title"
                        @click="props.onTwoDepthMenuClick($event, props.menuNavigationType, rootMenuInfo, twoDepthMenuInfo)">{{twoDepthMenuInfo.title}}</a>
                    <router-link v-else style="white-space: pre-wrap;"
                        :to="twoDepthMenuInfo.path"
                        :title="twoDepthMenuInfo.title"
                        @click="props.onTwoDepthMenuClick($event, props.menuNavigationType, rootMenuInfo, twoDepthMenuInfo)">{{twoDepthMenuInfo.title}}</router-link> 
                </li>
            </ul>
        </li>
    </ul>
    <ul v-else @mouseover="props.onRootMenuMouseOver" @mouseout="props.onRootMenuMouseOut">
        <li v-for="rootMenuInfo in state.menuItems" v-bind:key="rootMenuInfo" class="depth2">
            <a :href="rootMenuInfo.path" :title="rootMenuInfo.title" @click="props.onRootMenuClick($event, props.menuNavigationType, rootMenuInfo)">{{rootMenuInfo.title}}</a>
            <ul>
                <li v-for="twoDepthMenuInfo in rootMenuInfo.children" v-bind:key="twoDepthMenuInfo" :class="[{ depth3: twoDepthMenuInfo.children.length }]">
                    <a v-if="state.currentPagePathName === state.mainPagePathName"
                        :href="
                            (
                                twoDepthMenuInfo.children.find(child => child.isRepresentativeMenu)
                                ? twoDepthMenuInfo.children.find(child => child.isRepresentativeMenu).path
                                : twoDepthMenuInfo.path
                            )
                        "
                        :title="twoDepthMenuInfo.title"
                        @click="props.onTwoDepthMenuClick($event, props.menuNavigationType, rootMenuInfo, twoDepthMenuInfo)"
                        v-show="props.menuNavigationType != 2"
                    >{{twoDepthMenuInfo.title}}</a>
                    <router-link v-else
                        :to="
                            (
                                twoDepthMenuInfo.children.find(child => child.isRepresentativeMenu)
                                ? twoDepthMenuInfo.children.find(child => child.isRepresentativeMenu).path
                                : twoDepthMenuInfo.path
                            )
                        "
                        :title="twoDepthMenuInfo.title"
                        @click="props.onTwoDepthMenuClick($event, props.menuNavigationType, rootMenuInfo, twoDepthMenuInfo)"
                        v-show="props.menuNavigationType != 2"
                    >{{twoDepthMenuInfo.title}}</router-link>
                    <button v-show="props.menuNavigationType != 2" type="button" title="메뉴 열기"
                        @click="props.onTwoDepthMenuClick($event, props.menuNavigationType)">
                        <span class="text_hidden">메뉴 열기</span>
                    </button>

                    <div v-show="props.menuNavigationType == 2">
                        <a v-if="state.currentPagePathName === state.mainPagePathName"
                            :href="
                                (
                                    twoDepthMenuInfo.children.find(child => child.isRepresentativeMenu)
                                    ? twoDepthMenuInfo.children.find(child => child.isRepresentativeMenu).path
                                    : twoDepthMenuInfo.path
                                )
                            "
                            :title="twoDepthMenuInfo.title"
                            @click="props.onTwoDepthMenuClick($event, props.menuNavigationType, rootMenuInfo, twoDepthMenuInfo)"
                        >{{twoDepthMenuInfo.title}}</a>
                        <router-link v-else
                            :to="
                                (
                                    twoDepthMenuInfo.children.find(child => child.isRepresentativeMenu)
                                    ? twoDepthMenuInfo.children.find(child => child.isRepresentativeMenu).path
                                    : twoDepthMenuInfo.path
                                )
                            "
                            :title="twoDepthMenuInfo.title"
                            @click="props.onTwoDepthMenuClick($event, props.menuNavigationType, rootMenuInfo, twoDepthMenuInfo)"
                        >{{twoDepthMenuInfo.title}}</router-link>
                        <button type="button" title="메뉴 열기"
                            @click="props.onSideButtonClick($event)">
                            <span class="text_hidden">메뉴 열기</span>
                        </button>
                    </div>

                    <ul v-if="twoDepthMenuInfo.children.length">
                        <li v-for="threeDepthMenuInfo in twoDepthMenuInfo.children" v-bind:key="threeDepthMenuInfo">
                            <a v-if="state.currentPagePathName === state.mainPagePathName"
                                :href="threeDepthMenuInfo.path"
                                :title="threeDepthMenuInfo.title"
                                @click="props.onThreeDepthMenuClick(props.menuNavigationType, rootMenuInfo, twoDepthMenuInfo, threeDepthMenuInfo)">{{threeDepthMenuInfo.title}}</a>
                            <router-link v-else
                                :to="threeDepthMenuInfo.path"
                                :title="threeDepthMenuInfo.title"
                                @click="props.onThreeDepthMenuClick(props.menuNavigationType, rootMenuInfo, twoDepthMenuInfo, threeDepthMenuInfo)">{{threeDepthMenuInfo.title}}</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</template>
<script>
import { onMounted, reactive } from '@vue/runtime-core';
import { menuManagement, menuType } from '@/modules/menu-management.js';
export default {
    props: [

        // 메뉴 네비게이션 유형 (1: 상단 메뉴영역, 2: 메뉴맵, 3: 사이트맵 페이지 내 메뉴 맵)
        'menuNavigationType',

        // 메뉴클릭 이벤트 핸들러
        'onRootMenuClick',

        // 1뎁스 메뉴 클릭 이벤트 핸들러
        'onTwoDepthMenuClick',

        // 2뎁스 메뉴 클릭 이벤트 핸들러
        'onThreeDepthMenuClick',

        // 최상위 메뉴 마우스 오버 이벤트 핸들러
        'onRootMenuMouseOver',

        // 최상위 메뉴 마우스 아웃 이벤트 핸들러
        'onRootMenuMouseOut',

        // 모바일버전 2뎁스 메뉴 화살표 클릭 이벤트 핸들러
        'onSideButtonClick',
    ],
    setup(props) {

        // state (변수)
        const state = reactive({

            // 국문메인 페이지 경로
            mainPagePathName: '/main.html',

            // 영문메인 페이지 경로
            engMainPagePathName: '/eng-main.html',

            // 현재 페이지 경로
            currentPagePathName: location.pathname,

            // 메뉴 목록
            menuItems: menuManagement.getMenuItems(menuType.kor),

            // 메뉴 유형
            menuType: sessionStorage.getItem('menuType'),
        });

        /**
         * 메뉴 설정
         * @param {String} menuType: 메뉴유형 (KOR: 국문, ENG: 영문)
         */
        const setMenuItems = (menuType) => {

            // 메뉴 목록
            state.menuItems = menuManagement.getMenuItems(menuType);
        }
        
        onMounted(() => { });

        return {
            props,
            state,
            menuType,
            setMenuItems,
        }
    },
}
</script>