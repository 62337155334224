import { menuType } from "@/modules/menu-management";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: '/',
        name: 'Main',
        beforeEnter: () => {

            switch (sessionStorage.getItem('menuType')) {

                // 국문
                case menuType.kor: {
                    location.href = '/main.html';
                    break;
                }
                // 영문
                case menuType.eng: {

                    location.href = '/eng-main.html';
                    break;
                }
                // 기본
                default: {

                    location.href = '/main.html';
                    break;
                }
            }
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/router/views/Home'), // 동적 import
        children: [

            //#region 기타
            {
                path: '/others/total-search',
                name: 'TotalSearch',
                component: () => import('@/router/views/others/TotalSearch'),
            },
            //#endregion

            // #region 회사소개

            // 회사소개 > 연혁
            {
                path: '/company/about/history',
                name: 'History',
                component: () => import('@/router/views/company/about/History'),
            },
            // 회사소개 > 연혁 > CEO인사말
            {
                path: '/company/about/greet',
                name: 'Greet',
                component: () => import('@/router/views/company/about/Greet'),
            },
            // 회사소개 > 인력 및 조직
            {
                path: '/company/group/organization',
                name: 'Organization',
                component: () => import('@/router/views/company/group/Organization'),
            },
            // 회사소개 > 인력 및 조직 > 인재채용
            {
                path: '/company/group/talent-recruit-ment',
                name: 'TalentRecruitMent',
                component: () => import('@/router/views/company/group/TalentRecruitMent'),
            },
            // 회사소개 > 오시는 길
            {
                path: '/company/way-to-come/map',
                name: 'Map',
                component: () => import('@/router/views/company/way-to-come/Map'),
            },
            // 회사소개 > 고객센터 > 윤리경영
            {
                path: '/company/service-center/dclaration',
                name: 'Dclaration',
                component: () => import('@/router/views/company/service-center/Dclaration'),
            },
            // 회사소개 > 고객센터 > 고객서비스헌장
            {
                path: '/company/service-center/charter',
                name: 'Charter',
                component: () => import('@/router/views/company/service-center/Charter'),
            },
            // 회사소개 > 고객센터 > 개인정보보호정책
            {
                path: '/company/service-center/pri',
                name: 'Pri',
                component: () => import('@/router/views/company/service-center/Pri'),
            },
            // 회사소개 > 고객센터 > 개인(신용)정보보유현황
            {
                path: '/company/service-center/personal-info',
                name: 'PersonalInfo',
                component: () => import('@/router/views/company/service-center/PersonalInfo'),
            },
            // 회사소개 > 고객센터 > 개인(신용)정보이용철회
            {
                path: '/company/service-center/personalInfo-edit',
                name: 'PersonalInfoEdit',
                component: () => import('@/router/views/company/service-center/PersonalInfoEdit'),
            },
            // 회사소개 > 고객센터 > 이메일주소 무단수집거부
            {
                path: '/company/service-center/no-mail',
                name: 'NoMail',
                component: () => import('@/router/views/company/service-center/NoMail'),
            },
            // 회사소개 > 고객센터 > 공지사항
            {
                path: '/company/service-center/notice-board',
                name: 'NoticeBoard',
                component: () => import('@/router/views/company/service-center/NoticeBoard'),
            },
            // 회사소개 > 고객센터 > 공지사항 상세
            {
                path: '/company/service-center/notice-board-detail',
                name: 'NoticeBoardDetail',
                component: () => import('@/router/views/company/service-center/NoticeBoardDetail'),
            },
            // 회사소개 > 고객센터 > FAQ
            {
                path: '/company/service-center/faq',
                name: 'Faq',
                component: () => import('@/router/views/company/service-center/Faq'),
            },
            // 회사소개 > 고객센터 > 사이트맵
            {
                path: '/company/service-center/sitemap',
                name: 'Sitemap',
                component: () => import('@/router/views/company/service-center/Sitemap'),
            },
            // 회사소개 > 고객센터 > 온라인상담
            {
                path: '/company/service-center/advice',
                name: 'Advice',
                component: () => import('@/router/views/company/service-center/Advice'),
            },
            // 회사소개 > 고객센터 > 온라인상담 등록
            {
                path: '/company/service-center/advice-edit',
                name: 'AdviceEdit',
                component: () => import('@/router/views/company/service-center/AdviceEdit'),
            },
            // 회사소개 > 고객센터 > 이용약관
            {
                path: '/company/service-center/agreement',
                name: 'Agreement',
                component: () => import('@/router/views/company/service-center/Agreement'),
            },
            // 회사소개 > 고객센터 > 내부통제기준
            {
                path: '/company/service-center/internalControl',
                name: 'InternalControl',
                component: () => import('@/router/views/company/service-center/InternalControl'),
            },
            // 회사소개 > 고객센터 > 부동산신탁약관공시
            {
                path: '/company/service-center/realestate-agreement',
                name: 'RealestateAgreement',
                component: () => import('@/router/views/company/service-center/RealestateAgreement'),
            },
            // 회사소개 > 고객센터 > 투자권유준칙
            {
                path: '/company/service-center/investment-principle',
                name: 'InvestmentPrinciple',
                component: () => import('@/router/views/company/service-center/InvestmentPrinciple'),
            },
            // 회사소개 > 고객센터 > 금융소비자보호
            {
                path: '/company/service-center/consumer-protection',
                name: 'ConsumerProtection',
                component: () => import('@/router/views/company/service-center/ConsumerProtection'),
            },
            // #endregion 

            // #region 토지신탁 사업

            // 토지신탁사업 > 차입형토지신탁
            {
                path: '/land-trust/borrow-develop/leverag-land-trust',
                name: 'LeveragLandTrust',
                component: () => import('@/router/views/land-trust/borrow-develop/LeveragLandTrust'),
            },
            // 토지신탁사업 > 관리형토지신탁
            {
                path: '/land-trust/borrow-develop/management-land-trust',
                name: 'ManagementLandTrust',
                component: () => import('@/router/views/land-trust/borrow-develop/ManagementLandTrust'),
            },
            // 토지신탁사업 > 담보·관리·처분신탁 > 담보·관리·처분신탁
            {
                path: '/land-trust/non-develop/non-land-trust',
                name: 'NonLandTrust',
                component: () => import('@/router/views/land-trust/non-develop/NonLandTrust'),
            },
            // 토지신탁사업 > 담보·관리·처분신탁 > 분양관리신탁
            {
                path: '/land-trust/non-develop/parcel-out-management',
                name: 'ParcelOutManagement',
                component: () => import('@/router/views/land-trust/non-develop/ParcelOutManagement'),
            },
            // 토지신탁사업 > 담보·관리·처분신탁 > 대리사무
            // {
            //     path: '/land-trust/non-develop/proxy',
            //     name: 'Proxy',
            //     component: () => import('@/router/views/land-trust/non-develop/Proxy'),
            // },
            // 토지신탁사업 > 담보·관리·처분신탁 > 국공유지신탁
            // {
            //     path: '/land-trust/non-develop/public',
            //     name: 'Public',
            //     component: () => import('@/router/views/land-trust/non-develop/Public'),
            // },
            // 토지신탁사업 > 담보·관리·처분신탁 > 부동산컨설팅
            {
                path: '/land-trust/non-develop/consulting',
                name: 'Consulting',
                component: () => import('@/router/views/land-trust/non-develop/Consulting'),
            },
            // 토지신탁사업 > 담보·관리·처분신탁 > PCF Master
            // {
            //     path: '/land-trust/non-develop/pcf',
            //     name: 'Pcf',
            //     component: () => import('@/router/views/land-trust/non-develop/Pcf'),
            // },
            // 토지신탁사업 > 입주정보 > 현장정보
            {
                path: '/land-trust/move-In-Info/field-info',
                name: 'FieldInfo',
                component: () => import('@/router/views/land-trust/move-In-Info/FieldInfo'),
            },
            // 토지신탁사업 > 입주정보 > 현장정보 > 사업소개 > 사업개요
            {
                path: '/land-trust/move-In-Info/construction-view',
                name: 'ConstructionView',
                component: () => import('@/router/views/land-trust/move-In-Info/ConstructionView'),
            },
            // 토지신탁사업 > 입주정보 > 현장정보 > 사업소개 > 입주모집공고
            {
                path: '/land-trust/move-In-Info/construction-parcel',
                name: 'ConstructionParcel',
                component: () => import('@/router/views/land-trust/move-In-Info/ConstructionParcel'),
            },
            // 토지신탁사업 > 입주정보 > 현장정보 > 사업소개 > 분양사이트
            {
                path: '/land-trust/move-In-Info/construction-construction-etc',
                name: 'ConstructionEtc',
                component: () => import('@/router/views/land-trust/move-In-Info/ConstructionEtc'),
            },
            // 토지신탁사업 > 입주정보 > 현장정보 > 사업소개 > 오시는 길
            {
                path: '/land-trust/move-In-Info/construction-model',
                name: 'ConstructionModel',
                component: () => import('@/router/views/land-trust/move-In-Info/ConstructionModel'),
            },
            // 토지신탁사업 > 입주정보 > 현장정보 > 사업소개 > 공정현황
            {
                path: '/land-trust/move-In-Info/construction-process',
                name: 'ConstructionProcess',
                component: () => import('@/router/views/land-trust/move-In-Info/ConstructionProcess'),
            },
            // 토지신탁사업 > 입주정보 > 현장정보 > 사업소개 > 입주안내
            {
                path: '/land-trust/move-In-Info/construction-move-in',
                name: 'ConstructionMoveIn',
                component: () => import('@/router/views/land-trust/move-In-Info/ConstructionMoveIn'),
            },
            // 토지신탁사업 > 입주정보 > 현장정보 > 단지안내 > 단지배치도
            {
                path: '/land-trust/move-In-Info/construction-complex',
                name: 'ConstructionComplex',
                component: () => import('@/router/views/land-trust/move-In-Info/ConstructionComplex'),
            },
            // 토지신탁사업 > 입주정보 > 현장정보 > 단지안내 > 동호배치도
            {
                path: '/land-trust/move-In-Info/construction-feature',
                name: 'ConstructionFeature',
                component: () => import('@/router/views/land-trust/move-In-Info/ConstructionFeature'),
            },
            // 토지신탁사업 > 입주정보 > 현장정보 > 단지안내 > 세대정보
            {
                path: '/land-trust/move-In-Info/construction-plane',
                name: 'ConstructionPlane',
                component: () => import('@/router/views/land-trust/move-In-Info/ConstructionPlane'),
            },
            // 토지신탁사업 > 입주정보 > 현장정보 > 단지안내 > 주민공동시설
            {
                path: '/land-trust/move-In-Info/construction-interior',
                name: 'ConstructionInterior',
                component: () => import('@/router/views/land-trust/move-In-Info/ConstructionInterior'),
            },
            // 토지신탁사업 > 입주정보 > 분양대금조회
            {
                path: '/land-trust/move-In-Info/payment',
                name: 'Payment',
                component: () => import('@/router/views/land-trust/move-In-Info/Payment'),
            },
            // 토지신탁사업 > 입주정보 > 분양대금조회상세
            {
                path: '/land-trust/move-In-Info/payment-view',
                name: 'PaymentView',
                component: () => import('@/router/views/land-trust/move-In-Info/PaymentView'),
            },
            // 토지신탁사업 > 공매공고
            {
                path: '/land-trust/sale/short-notice',
                name: 'ShortNotice',
                component: () => import('@/router/views/land-trust/sale/ShortNotice'),
            },
            // 토지신탁사업 > 공매공고 상세
            {
                path: '/land-trust/sale/short-notice-detail',
                name: 'ShortNoticeDetail',
                component: () => import('@/router/views/land-trust/sale/ShortNoticeDetail'),
            },
            // 토지신탁사업 > 기타공고
            {
                path: '/land-trust/etc/other-notice',
                name: 'OtherNotice',
                component: () => import('@/router/views/land-trust/etc/OtherNotice'),
            },
            // 토지신탁사업 > 기타공고 상세
            {
                path: '/land-trust/etc/other-notice-detail',
                name: 'OtherNoticeDetail',
                component: () => import('@/router/views/land-trust/etc/OtherNoticeDetail'),
            },
            // #endregion

            // #region 도시정비사업

            // 신탁방식 도시정비사업
            {
                path: '/city-maintain/trust-system/redevelop',
                name: 'Redevelop',
                component: () => import('@/router/views/city-maintain/trust-system/Redevelop'),
            },

            // #endregion

            // #region 리츠.투자사업

            // 리츠.투자사업 > 리츠소개
            {
                path: '/reits-invest/Investment/reits-business',
                name: 'ReitsBusiness',
                component: () => import('@/router/views/reits-invest/Investment/ReitsBusiness'),
            },
            // 리츠.투자사업 > 리츠 퍼포먼스
            {
                path: '/reits-invest/Investment/reits-performance',
                name: 'ReitsPerformance',
                component: () => import('@/router/views/reits-invest/Investment/ReitsPerformance'),
            },
            // 리츠.투자사업 > 리츠 운영 현황
            {
                path: '/reits-invest/Investment/reits-operation-status',
                name: 'ReitsOperationStatus',
                component: () => import('@/router/views/reits-invest/Investment/ReitsOperationStatus'),
            },
            // 리츠.투자사업 > 투자사업 > PFV
            {
                path: '/reits-invest/Investment/pfv-business',
                name: 'PfvBusiness',
                component: () => import('@/router/views/reits-invest/Investment/PfvBusiness'),
            },
            // 리츠.투자사업 > 투자사업 > 해외사업
            // {
            //     path: '/reits-invest/Investment/overseas-business',
            //     name: 'OverseasBusiness',
            //     component: () => import('@/router/views/reits-invest/Investment/OverseasBusiness'),
            // },
            // #endregion

            // #region IR.PR센터
            
            // IR·PR센터 > ESG
            {
                path: '/ir-pr-center/esg/enviro-social-gov',
                name: 'EnviroSocialGov',
                component: () => import('@/router/views/ir-pr-center/esg/EnviroSocialGov'),
            },
            // IR·PR센터 > 사업 포트폴리오
            {
                path: '/ir-pr-center/business-portfolio/portfolio',
                name: 'Portfolio',
                component: () => import('@/router/views/ir-pr-center/business-portfolio/Portfolio'),
            },
            // IR·PR센터 > 사업 포트폴리오 > IR자료
            {
                path: '/ir-pr-center/business-portfolio/ir-data',
                name: 'IrData',
                component: () => import('@/router/views/ir-pr-center/business-portfolio/IrData'),
            },
            // IR·PR센터 > 사업 포트폴리오 > IR자료 상세
            {
                path: '/ir-pr-center/business-portfolio/ir-data-detail',
                name: 'IrDataDetail',
                component: () => import('@/router/views/ir-pr-center/business-portfolio/IrDataDetail'),
            },
            // IR·PR센터 > 사업 포트폴리오 > 배당현황
            {
                path: '/ir-pr-center/business-portfolio/dividend-status',
                name: 'DividendStatus',
                component: () => import('@/router/views/ir-pr-center/business-portfolio/DividendStatus'),
            },
            // IR·PR센터 > 경영공시 > 영업보고
            {
                path: '/ir-pr-center/management/sales-report',
                name: 'SalesReport',
                component: () => import('@/router/views/ir-pr-center/management/SalesReport'),
            },
            // IR·PR센터 > 경영공시 > 영업보고 상세
            {
                path: '/ir-pr-center/management/sales-report-detail',
                name: 'SalesReportDetail',
                component: () => import('@/router/views/ir-pr-center/management/SalesReportDetail'),
            },
            // IR·PR센터 > 경영공시 > 수시공시
            {
                path: '/ir-pr-center/management/payout-disclosure',
                name: 'PayoutDisclosure',
                component: () => import('@/router/views/ir-pr-center/management/PayoutDisclosure'),
            },
            // IR·PR센터 > 경영공시 > 수시공시 상세
            {
                path: '/ir-pr-center/management/payout-disclosure-detail',
                name: 'PayoutDisclosureDetail',
                component: () => import('@/router/views/ir-pr-center/management/PayoutDisclosureDetail'),
            },
            // IR·PR센터 > 경영공시 > 지배구조공시
            {
                path: '/ir-pr-center/management/ground-disclosure',
                name: 'GroundDisclosure',
                component: () => import('@/router/views/ir-pr-center/management/GroundDisclosure'),
            },
            // IR·PR센터 > 경영공시 > 지배구조공시 상세
            {
                path: '/ir-pr-center/management/ground-disclosure-detail',
                name: 'GroundDisclosureDetail',
                component: () => import('@/router/views/ir-pr-center/management/GroundDisclosureDetail'),
            },
            // IR·PR센터 > 경영공시 > 결산공시
            {
                path: '/ir-pr-center/management/settlement-disclosure',
                name: 'SettlementDisclosure',
                component: () => import('@/router/views/ir-pr-center/management/SettlementDisclosure'),
            },
            // IR·PR센터 > 경영공시 > 결산공시 상세
            {
                path: '/ir-pr-center/management/settlement-disclosure-detail',
                name: 'SettlementDisclosureDetail',
                component: () => import('@/router/views/ir-pr-center/management/SettlementDisclosureDetail'),
            },
            // IR·PR센터 > 경영공시 > 기타공시
            {
                path: '/ir-pr-center/management/etc-disclosure',
                name: 'EtcDisclosure',
                component: () => import('@/router/views/ir-pr-center/management/EtcDisclosure'),
            },
            // IR·PR센터 > 경영공시 > 기타공시 상세
            {
                path: '/ir-pr-center/management/etc-disclosure-detail',
                name: 'EtcDisclosureDetail',
                component: () => import('@/router/views/ir-pr-center/management/EtcDisclosureDetail'),
            },
            // IR·PR센터 > 경영공시 > 리츠공시
            {
                path: '/ir-pr-center/reits/reits-disclosure',
                name: 'ReitsDisclosure',
                component: () => import('@/router/views/ir-pr-center/reits/ReitsDisclosure'),
            },
            // IR·PR센터 > 경영공시 > 리츠공시 상세
            {
                path: '/ir-pr-center/reits/reits-disclosure-detail',
                name: 'ReitsDisclosureDetail',
                component: () => import('@/router/views/ir-pr-center/reits/ReitsDisclosureDetail'),
            },
            // IR·PR센터 > KOREIT 아카이브 > CI 소개
            {
                path: '/ir-pr-center/koreit-archive/ci-bi',
                name: 'CiBi',
                component: () => import('@/router/views/ir-pr-center/koreit-archive/CiBi'),
            },
            // IR·PR센터 > KOREIT 아카이브 > 브로슈어
            {
                path: '/ir-pr-center/koreit-archive/brochure',
                name: 'Brochure',
                component: () => import('@/router/views/ir-pr-center/koreit-archive/Brochure'),
            },
            // IR·PR센터 > KOREIT 아카이브 > 코아루체 소개
            {
                path: '/ir-pr-center/koreit-archive/koaroo-penmanship',
                name: 'KoarooPenmanship',
                component: () => import('@/router/views/ir-pr-center/koreit-archive/KoarooPenmanship'),
            },
            // IR·PR센터 > KOREIT 아카이브 > 기업/브랜드 필름
            {
                path: '/ir-pr-center/koreit-archive/enterprise-film',
                name: 'EnterpriseFilm',
                component: () => import('@/router/views/ir-pr-center/koreit-archive/EnterpriseFilm'),
            },

            // #endregion

            // #region 영문사이트
            {
                path: '/en/about-koreit/ceo-message',
                name: 'CEOMessage',
                component: () => import('@/router/views/en/about-koreit/CEOMessage'),
            },
            {
                path: '/en/about-koreit/map-of-location',
                name: 'MapOfLocation',
                component: () => import('@/router/views/en/about-koreit/MapOfLocation'),
            },
            {
                path: '/en/about-koreit/milestones',
                name: 'Milestones',
                component: () => import('@/router/views/en/about-koreit/Milestones'),
            },
            {
                path: '/en/about-koreit/organization',
                name: 'EngOrganization',
                component: () => import('@/router/views/en/about-koreit/Organization'),
            },
            {
                path: '/en/koreit-service/land-trust',
                name: 'LandTrust',
                component: () => import('@/router/views/en/koreit-service/LandTrust'),
            },
            {
                path: '/en/koreit-service/land-trust-led-urban-improvement-project',
                name: 'LandTrust-ledUrbanImprovementProject',
                component: () => import('@/router/views/en/koreit-service/LandTrust-ledUrbanImprovementProject'),
            },
            {
                path: '/en/koreit-service/reits',
                name: 'REITs',
                component: () => import('@/router/views/en/koreit-service/REITs'),
            },
            {
                path: '/en/koreit-service/investment-business',
                name: 'InvestmentBusiness',
                component: () => import('@/router/views/en/koreit-service/InvestmentBusiness'),
            },
            // {
            //     path: '/en/koreit-service/introduction',
            //     name: 'Introduction',
            //     component: () => import('@/router/views/en/koreit-service/Introduction'),
            // },
            // {
            //     path: '/en/koreit-service/land-development-trust',
            //     name: 'LandDevelopmentTrust',
            //     component: () => import('@/router/views/en/koreit-service/LandDevelopmentTrust'),
            // },
            // {
            //     path: '/en/koreit-service/management-trust',
            //     name: 'ManagementTrust',
            //     component: () => import('@/router/views/en/koreit-service/ManagementTrust'),
            // },
            // {
            //     path: '/en/koreit-service/profit-certificate-trust',
            //     name: 'ProfitCertificateTrust',
            //     component: () => import('@/router/views/en/koreit-service/ProfitCertificateTrust'),
            // },
            // {
            //     path: '/en/koreit-service/proxy-business',
            //     name: 'ProxyBusiness',
            //     component: () => import('@/router/views/en/koreit-service/ProxyBusiness'),
            // },
            // {
            //     path: '/en/koreit-service/real-estate-consulting',
            //     name: 'RealEstateConsulting',
            //     component: () => import('@/router/views/en/koreit-service/RealEstateConsulting'),
            // },
            // {
            //     path: '/en/koreit-service/reits',
            //     name: 'REITs',
            //     component: () => import('@/router/views/en/koreit-service/REITs'),
            // },
            // {
            //     path: '/en/koreit-service/abs',
            //     name: 'ABS',
            //     component: () => import('@/router/views/en/koreit-service/ABS'),
            // },
            // #endregion
        ],
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },

});

export default router;
